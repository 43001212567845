import { useEffect, useState } from 'react';
import { Route, Routes } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { selectUser, setUser } from './features/isUserAuthSlice';

import Nav from './components/Nav/Nav';
import Footer from './components/Footer';
import Login from './components/Login/Login';
import Signup from './components/Signup';
import About from './components/About/About';
import Leagues from './components/Leagues';
import Contact from './components/Contact';
import Merch from './components/Merch';
import Main from './components/Main';
import Profile from './components/Profile';
import Tos from './components/Tos';
import StreetBallWeather from './components/StreetBallWeather';
import './App.css';

function App() {

  const backendURL = process.env.NODE_ENV === 'development' ? 'http://localhost:4523' : 'https://hewitus-backend.onrender.com';

  // const [users, setUsers] = useState([]);

  useEffect( () => {
    // Connect to the backend
    const fetchData = async () => {

      try {
        const res = await fetch(`${backendURL}/api/users`);
        // console.log( 'res.json() --> ', await res.json() );
        const users = await res.json();
        // console.log( 'users ----> ', users );
        // const data = await res.json();

        // Set data to the state users variable
        // setUsers(users);
      } catch (error) {
        console.log(error);
      }
      
    }

    fetchData();
    // console.log( 'from app.js useEffect - users --> ', users );

  }, []);

  const localStorageUser = JSON.parse( localStorage.getItem('hwuUser') );
  const dispatch = useDispatch();

  if ( localStorageUser ) {
    dispatch( setUser(localStorageUser) );
  }

  return (
    <div className="App">
      <header className="App-header hwuRow">
        <Nav />
      </header>
      <main>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/about" element={<About />} />
          <Route path="/leagues" element={<Leagues />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/store" element={<Merch />} />
          <Route path="/tos" element={<Tos />} />
          <Route path="/street-ball-weather" element={<StreetBallWeather />} />
        </Routes>
      </main>
        <footer className="hwuRow hwuFooter">
          <Footer />
        </footer>
    </div>
  );
}

export default App;
